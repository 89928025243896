import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "multi-select",
      "style": {
        "position": "relative"
      }
    }}>{`Multi Select`}</h1>
    <p>{`São usados quando há a opção de selecionar mais de um item de uma lista e conta com 5 ou mais itens. O componente permite que o usuário realize buscas na lista e também apresenta os itens que já foram selecionados dentro do campo, além da possibilidade de selecionar todos os itens de uma vez.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`A ordem dos itens da lista deve ser baseada na frequência de uso e, se aplicável, em ordem crescente.`}</li>
      <li parentName="ul">{`Se a lista possuir menos de 5 itens, é recomendável que as opções sejam apresentadas diretamente na tela utilizando o componente de `}<a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a>{`.`}</li>
      <li parentName="ul">{`Sempre que possível agrupe as opções e utilize títulos curtos que descrevam o conteúdo da categoria (Ex.: Em um combo de alimentos, as opções podem ser agrupadas por: vegetais, frutas, etc.).`}</li>
      <li parentName="ul">{`Para situações onde é possível selecionar apenas um item utilize `}<a parentName="li" {...{
          "href": "/components/select"
        }}>{`Select`}</a>{` ou `}<a parentName="li" {...{
          "href": "/components/radio-button"
        }}>{`Radio Button`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplo",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo`}</h2>
    <Demo src='pages/components/multi-select/MultiSelect' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      